<template>
  <div class="doctor">
    <el-alert
      title="请在此处配置患者手机端首页快捷入口，拖动调整各个快捷入口的排列先后顺序。"
      type="success"
      :closable="false"
      style="margin: 9px 0 24px"
    ></el-alert>
    <div class="search-list">
      <el-button type="primary" @click="add('docAdd')">添加</el-button>
    </div>
    <el-table
      :data="tableData"
      ref="tableRef"
      row-key="id"
      style="width: 100%; margin-top: 10px"
    >
      <el-table-column prop="date" label="应用排序">
        <template slot-scope="scope">
          <img src="../../assets/icon/icon-15.png" alt="" />
        </template>
      </el-table-column>
      <el-table-column prop="navigateName" label="应用名称"></el-table-column>
      <el-table-column prop="address" label="icon图标">
        <template slot-scope="scope">
          <div v-if="scope.row.navigateIcon">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.navigateIcon"
              fit="fill"
            ></el-image>
          </div>
          <div v-else>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/daitianxie.png')"
              fit="fill"
              v-if="scope.row.navigateName == '待填写'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/ceping.png')"
              fit="fill"
              v-if="scope.row.navigateName == '主要体征'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/tizheng.png')"
              fit="fill"
              v-if="scope.row.navigateName == '自我测评'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/suifang.png')"
              fit="fill"
              v-if="scope.row.navigateName == '随访方案'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/biliben.png')"
              fit="fill"
              v-if="scope.row.navigateName == '病历本'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/yinshi.png')"
              fit="fill"
              v-if="scope.row.navigateName == '饮食日记'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/yaoqing.png')"
              fit="fill"
              v-if="scope.row.navigateName == '邀请患友'"
            ></el-image>
            <el-image
              style="width: 50px; height: 50px"
              :src="require('@/assets/img/quick/wenjuan.png')"
              fit="fill"
              v-if="scope.row.navigateName.indexOf('问卷') > -1"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="isDefault" label="入口性质">
        <template slot-scope="scope">{{
          scope.row.isDefault == 1
            ? "默认应用"
            : scope.row.isDefault == 2
            ? "自定义应用"
            : null
        }}</template>
      </el-table-column>
      <el-table-column label="应用状态">
        <template slot-scope="scope">{{
          scope.row.isEnable == 1 ? "启用" : "未启用"
        }}</template>
      </el-table-column>
      <el-table-column label="操作" align="right" header-align="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="edit(scope.row, 'docEdit')"
            size="small"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.isDefault == 2"
            type="text"
            @click="del(scope.row)"
            size="small"
            style="color: red"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      class="quickconfig-form"
    >
      <DialogView
        :message="message"
        :type="type"
        :orderNum="orderNum"
        :row="row"
        :isShow="dialogVisible"
        @closeDialog="closeDialog"
        @quxiao="closedialog"
      />
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import DialogView from "./dialog.vue";
export default {
  components: {
    DialogView,
  },

  data() {
    return {
      type: 2,
      tableData: [], //列表
      dialogVisible: false,
      title: "",
      message: "",
      orderNum: 0,
      row: {},
      oldList: [],
      newList: [],
      newIndexList: [],
      imgUrl: this.$global.imgUrl,
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.initSortableList();
  },

  methods: {
    closedialog() {
      this.dialogVisible = false;
    },
    //行拖拽
    /* rowDrop() {
        const tbody = document.querySelector('.el-table__body-wrapper tbody')
        const _this = this
        Sortable.create(tbody, {
          onEnd({ newIndex, oldIndex}) {
            
            const currRow = _this.tableData.splice(oldIndex, 1)[0]
            _this.tableData.splice(newIndex, 0, currRow)
            currRow.orderNum = newIndex+1
            
            _this.$http({
              url: _this.$http.adornUrl("/teamNavigate/update"),
              method: "post",
              params: {
                teamsId: JSON.parse(localStorage.currentTeam).id,
                ...currRow
              }
            }).then(res=>{
              if(res.data.status){
                _this.getList()
              }
            })
          }
        })
      }, */
    initSortableList() {
      let el = this.$refs.tableRef.$el.querySelector(
        ".el-table__body-wrapper tbody"
      );
      //设置配置
      let _this = this;
      Sortable.create(el, {
        animation: 150,
        sort: true,
        draggable: ".el-table__row ", // 设置可拖拽行的类名(el-table自带的类名)
        // forceFallback: true,
        onEnd({ newIndex, oldIndex }) {
          let currRow = _this.newIndexList.splice(oldIndex, 1)[0];
          _this.newIndexList.splice(newIndex, 0, currRow);
          const list = _this.newIndexList.map((item) => item);
          list.reverse();

          const promises = [];

          list.forEach((item, index) => {
            item.orderNum = index + 1;
            const promise = new Promise((resolve, reject) => {
              _this
                .$http({
                  url: _this.$http.adornUrl("/teamNavigate/update"),
                  method: "post",
                  params: item,
                })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            });
            promises.push(promise);
          });

          Promise.all(promises)
            .then(() => {
              // 所有请求都完成
              _this.getList();
              console.log("所有请求都完成");
            })
            .catch((error) => {
              // 至少一个请求被拒绝
              console.error("发生错误", error);
            });
        },
      });
    },
    getList() {
      this.$httpAes({
        url: this.$httpAes.adornUrl("/teamNavigate/list"),
        method: "post",
        params: {
          stringParam1: JSON.parse(localStorage.currentTeam).id,
          intParam3: 2,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.tableData = res.data.data;
          // this.tableData.reverse()
          this.orderNum = this.tableData.length;
          // this.tableData.forEach( item => {
          //   this.newIndexList.push(item)
          // });
          this.newIndexList = this.tableData;
        }
      });
    },

    edit(row, message) {
      this.title = "编辑";
      this.message = message;
      this.row = row;
      this.dialogVisible = true;
    },
    add(message) {
      this.title = "添加";
      this.message = message;

      this.dialogVisible = true;
    },
    del(row) {
      this.$confirm("此操作将删除该图标, 是否继续?", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        this.$httpAes({
          url: this.$httpAes.adornUrl("/teamNavigate/delete"),
          method: "post",
          params: {
            stringParam1: row.id,
            type: this.type,
          },
        }).then((res) => {
          if (res.data.status) {
            this.$message.success("删除成功");
            this.getList();
          }
        });
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-alert--success.is-light {
  background-color: #e6f5ff;
  color: black;
  border: 1px solid #0099ff;
}
.quickconfig-form {
  /deep/.el-dialog__body {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
