<template>
   
  <div>
    <el-upload
      class="avatar-uploader"
      :action="actionUrl"
      list-type="picture"
      :multiple="false"
      :show-file-list="false"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
      :before-upload="beforeAvatarUpload"
    >
      <!-- :data="dataObj" -->
      <img
        v-if="fileList[0].url"
        :src="imgUrl + fileList[0].url"
        class="avatar"
      />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <span style="color: #999" v-if="isShowTip"
      >(建议尺寸200*200px)</span
    >
    <span style="color: #999" v-if="isWxCode"
      >*公众号二维码上传后，将在网站首页的底部展示。</span
    >
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileList[0].url" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "singleUpload",
  props: {
    value: String,
    isShowTip: true,
    isWxCode: false,
  },
  computed: {
    imageUrl() {
      return this.value;
    },
    imageName() {
      if (this.value != null && this.value !== "") {
        return this.value.substr(this.value.lastIndexOf("/") + 1);
      } else {
        return null;
      }
    },
    fileList() {
      return [
        {
          name: this.imageName,
          url: this.imageUrl,
        },
      ];
    },
    showFileList: {
      get: function () {
        return (
          this.value !== null && this.value !== "" && this.value !== undefined
        );
      },
      set: function (newValue) {},
    },
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
      },
      dialogVisible: false,
      imgUrl: this.$global.imgUrl,
      actionUrl: "123",
    };
  },
  methods: {
    emitInput(val) {
      this.$emit("input", val);
    },
    handleRemove(file, fileList) {
      this.emitInput("");
    },
    handlePreview(file) {
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      let type = lastName.toLowerCase();
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (type != ".jpg" && type != ".jpeg" && type != ".png") {
        this.$message.error("图片格式只支持jpg、jpeg、png格式");
        return;
      }
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
        return;
      }
      this.loading = true;
      let instance = axios.create({
        timeout: 2000,
      });
      let url = this.$global.baseURL + "/aliyun/oss/upload";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("isToken", 2);
      instance
        .post(url, formData)
        .then(({ data }) => {
          this.$message({
            message: "文件上传成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.handleUploadSuccess(data,file)
            },
          });
        })
        .catch(() => {
          this.loading = false;
          failure("服务器出了点小差");
        });
    },
    handleUploadSuccess(res, file) {
      this.fileList.pop();
      this.fileList.push({
        name: res.result.path,
        url: this.imgUrl + res.result.path,
      });
      this.emitInput(this.fileList[0].name);
    },
  },
};
</script>
<style>
</style>


