<template>
    <el-tabs v-model="activeName" type="card" >
      <el-tab-pane label="患者端" name="1">
        <PatientView />
      </el-tab-pane>
      <el-tab-pane label="医生端" name="2">
        <DoctorView  />
      </el-tab-pane>
    </el-tabs>
</template>
    

<script>
import DoctorView from "./DoctorView.vue";
import PatientView from "./PatientView.vue"
  export default {
    data() {
      return {
        activeName: '1'
      }
    },
    methods: {
      huanzhe() {
        this.activeName = 1
      },
      doctor() {
        this.activeName = 2
      }
    },
    components: {
      DoctorView,
      PatientView
    }
  }
</script>

<style lang="scss" scoped>
.table{
  display: flex;
  .huanzhe,.doctor{
    padding: 10px;
    border: 1px solid gray;
    margin:0px -1px -1px 0px ;
    border-radius: 6px; 
    cursor: pointer;
    margin-bottom: 10px;
  }
  .ys{
    background-color: #3EAAFF;
    color: #fff;
  }
}
</style>